<template>
    <v-navigation-drawer
        app
        clipped
        v-model="DRAWER_STATE"
        :mini-variant="!DRAWER_STATE"
        :width="sidebarWidth"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :temporary="$vuetify.breakpoint.smAndDown"
        :mini-variant-width="sidebarMinWidth"
        :class="{'drawer-mini': !DRAWER_STATE}">

        <v-card >
            <v-card-title class="justify-center">
                <v-img src="@/assets/image/LogoGris.png" contain width="250px" height="auto" class="mx-auto"></v-img>
            </v-card-title>
        </v-card>

        <v-list rounded v-if="menu.length">
            <template v-for="(item) in menu">
                <v-list-group
                    :value="true"
                    color="primary"
                    v-if="item.children && DRAWER_STATE"
                    :key="item.title"
                    v-model="item.model"
                >
                    <template v-slot:prependIcon>
                        <v-icon size="28">{{ item.icon }}</v-icon>
                    </template>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        v-for="(child, i) in item.children"
                        :key="i"
                        :to="child.link"
                        link>
                        <v-list-item-action v-if="child.icon">
                            <v-icon size="">{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                    color="primary"
                    v-else
                    :key="item.text"
                    :href="item.href ? item.href : null"
                    :to="item.link === '#' ? null : item.link"
                    link>
                    <v-list-item-action>
                        <v-icon
                            size="28"
                            :color="item.color ? item.color : ''"
                        >{{ item.icon }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title link>
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <v-list v-else-if="!menu.length && !loading">
            <v-list-item color="primary">
                <v-list-item-action>
                    <v-icon color="error"
                            size="28"
                    >
                        hide_source
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content color="error">
                    <v-list-item-title link>
                        No tienes Permiso
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Ls from "@/services/ls";

export default {
    props: {
        source: String,
    },
    name: "Sidebar",
    data() {

        return {
            menu: [],
            sidebarWidth: 240,
            sidebarMinWidth: 96,
            loading: false,
        }
    },
    computed: {
        ...mapState(['drawer']),
        DRAWER_STATE: {
            get() {
                return this.drawer
            },
            set(newValue) {
                if (newValue === this.drawer) return;
                this.TOGGLE_DRAWER();
            }
        }
    },
    methods: {
        ...mapActions(['TOGGLE_DRAWER']),
        menuGet() {
            this.loading = true;

            let params = {
                cat_rol_id: Ls.get("rol")
            }


            window.axios.get('/usuarios/menu', {params}).then(response => {
                if (response.data.success) {

                    let data = response.data.data;

                    // Si es array entonces asignar
                    if (Array.isArray(data)) {
                        this.menu = data;
                    }

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo saber tu Menú.");
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    created() {
        this.menuGet()
    }
}
</script>

<style src="./Sidebar.scss" lang="scss"/>
