<template>
    <v-container fluid v-show="$route.path != '/inicio'">
        <v-breadcrumbs :items="items" class="elevation-1 text-uppercase">
            <template v-slot:divider>
                <v-icon size="25">navigate_next</v-icon>
            </template>
            <template v-slot:item="{ item }">
                <router-link :to="{ name: item.href }">
                    {{ item.name }}
                </router-link>
            </template>
        </v-breadcrumbs>
    </v-container>

</template>

<script>
export default {
    name: 'Breadcrumb',
    data() {
        return {
            items: []
        }
    },

    watch: {
        '$route'() {
            this.updateBreadcrumbs();
        }
    },

    mounted() {
        this.updateBreadcrumbs();
    },

    methods: {
        updateBreadcrumbs() {
            this.items = this.$route.meta.breadcrumb;
        }
    }
}
</script>

<style lang="scss" scoped>
@import './Breadcrumb.scss';
</style>
