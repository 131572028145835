<template>
    <v-app class="pa-6">
        <Header/>
        <Sidebar/>
        <v-main class="content">
            <Breadcrumb/>
            <router-view/>
            <Footer/>
        </v-main>
    </v-app>
</template>

<script>
import Header from '@/components/Header/Header';
import Sidebar from '@/components/Sidebar/Sidebar';
import Footer from "@/components/Footer/Footer";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import './Layout.scss';

export default {
    name: 'Layout',
    components: {Footer, Header, Sidebar, Breadcrumb},
};
</script>

<style lang="scss" scoped>
@import './Layout.scss';
</style>
