<template>
    <v-footer height="auto" fixed="fixed">
        <v-card class="flex">
            <v-card-actions class="justify-center">
                <v-icon>copyright</v-icon>
                <b>{{ $store.state.anyoFooter }} | {{ $store.state.appName }}</b>
            </v-card-actions>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    name: 'Footer',

    data: () => ({
        //
    }),
}
</script>
<style lang="scss" scoped>
@import './Footer.scss';
</style>
