<template>
    <v-app-bar
        class="main-header"
        height="50"
        fixed
        color='primary'
        dark>
        <v-btn icon class="mx-1" @click.stop="TOGGLE_DRAWER">
            <template v-if="DRAWER_STATE">
               <v-icon size="28">menu_open</v-icon>
            </template>
            <template v-else>
                <v-icon size="28" class="rotate-180">menu_open</v-icon>
            </template>
        </v-btn>
        <v-toolbar-title class="text-h4">{{$store.state.appName}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <p class="mt-5 text_username">{{ personaModel.nombre }} {{ personaModel.apellido }}</p>
        <v-menu
            min-width="180"
            offset-y
            bottom
            left
            nudge-bottom="10">

            <template v-slot:activator="{ on, attrs }">

                <v-btn
                    class="mr-0"
                    icon
                    v-bind="attrs"
                    v-on="on">
                    <v-icon size="40">
                        account_circle
                    </v-icon>
                </v-btn>

            </template>
            <v-list>
                <v-list-item-group color="primary"
                                   v-show="personaModel.cat_tipo_persona_id == 1 || personaModel.cat_tipo_persona_id == 2">
                    <v-list-item to="/mi_perfil">
                        <v-list-item-icon class="mr-4">
                            <v-icon>
                                assignment_ind
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title :color='config.light.textColor'>Mi Perfil</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                <div class="d-flex justify-center my-3">
                    <v-btn
                        width="80%"
                        outlined
                        color="info"
                        class="text-capitalize"
                        @click="logOut">
                        <v-icon>logout</v-icon>
                        Salir
                    </v-btn>
                </div>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import config from '../../config';

import Auth from "@/services/auth";
import Ls from "@/services/ls";

export default {
    name: 'Header',
    components: {},
    data: () => ({
        config,
        personaModel: {
            cat_tipo_persona_id: Ls.get('tipo_persona'),
            nombre: Ls.get('nombre'),
            apellido: Ls.get('apellido')
        },
    }),
    computed: {
        ...mapState(['drawer']),
        DRAWER_STATE: {
            get() {
                return this.drawer
            },
        }
    },
    methods: {
        ...mapActions(['TOGGLE_DRAWER']),
        logOut: function () {
            let tipoPersona = Number(Ls.get("tipo_persona"));
            let cat_rol_id = Ls.get("rol");
            Auth.logout().then(() => {
                let routeName = "LoginCertificados";
                if (tipoPersona == 1 || tipoPersona == 2) {
                    routeName = "LoginAdmin";
                } else if (tipoPersona == 3 && cat_rol_id == 2) {
                    routeName = "LoginCalificaciones";
                }
                this.$router.push({name: routeName});
            })
        }
    }
};
</script>

<style lang="scss" scoped>
@import './Header.scss';
</style>
